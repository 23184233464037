@charset "utf-8";
/* TOC – Color Variables

- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/



/* Basics
------------------------------------------------------------------- */

$text-color         : #111;
$body-font-color    : $text-color;
$body-bg            : #fdfdfd;



/* Corporate Identity Colorpalette
   https://www.colgate.edu/about/offices-centers-institutes/office-communications/identity-guidelines/design-elements#colorpal
------------------------------------------------------------------- */

$ci-1               : #334D5C; // dark turquoise
$ci-2               : #E10028; // Maple Red
$ci-3               : #EFC94C; // yellow
$ci-4               : #E27A3F; // orange
$ci-5               : #D2D4D6; // Winter Gray
$ci-6               : #821019; // Colgate Maroon 
$colgate-maroon     : #821019; 
$maple-red          : #E10028;
$black              : #000000; 
$shadow-gray        : #5A646E;
$winter-gray        : #D2D4D6;



/* Foundation Color Variables
------------------------------------------------------------------- */

$primary-color      : $ci-1;
$secondary-color    : $ci-6;
$alert-color        : $ci-5;
$success-color      : $ci-6;
$warning-color      : $ci-4;
$info-color         : $ci-1;



/* Grey Scale
------------------------------------------------------------------- */

$grey-1             : #E4E4E4;
$grey-2             : #D7D7D7;
$grey-3             : #CBCBCB;
$grey-4             : #BEBEBE;
$grey-5             : #A4A4A4;
$grey-6             : #979797;
$grey-7             : #8B8B8B;
$grey-8             : #7E7E7E;
$grey-9             : #646464;
$grey-10            : #575757;
$grey-11            : #4B4B4B;
$grey-12            : #3E3E3E;
$grey-13            : #313131;
$grey-14            : #242424;
$grey-15            : #171717;
$grey-16            : #0B0B0B;



/* Topbar-Navigation
------------------------------------------------------------------- */

$topbar-bg-color    : $colgate-maroon;
$topbar-bg          : $topbar-bg-color;


$topbar-dropdown-toggle-color: $ci-1;

$topbar-link-color  : #fff;
$topbar-link-color-hover: #fff;
$topbar-link-color-active: #fff;
$topbar-link-color-active-hover: #fff;

$topbar-dropdown-label-color: $colgate-maroon;
$topbar-dropdown-link-bg-hover: $maple-red;

$topbar-link-bg-active: $colgate-maroon; // Active Navigation Link
$topbar-link-bg-hover: $maple-red;
$topbar-link-bg-active-hover: $ci-2;


$topbar-dropdown-bg: $colgate-maroon; // Background Mobile Navigation
$topbar-dropdown-link-color: #fff;
$topbar-dropdown-link-bg: $colgate-maroon;

$topbar-menu-link-color-toggled: $ci-1;
$topbar-menu-icon-color-toggled: $ci-1;
$topbar-menu-link-color: #fff;
$topbar-menu-icon-color: #fff;
$topbar-menu-link-color-toggled: $ci-6;
$topbar-menu-icon-color-toggled: $ci-6;



/* Footer
------------------------------------------------------------------- */

$footer-bg          : $colgate-maroon;
$footer-color       : #fff;
$footer-link-color  : $ci-6;


$subfooter-bg       : #44060b;
$subfooter-color    : $grey-8;
$subfooter-link-color: $grey-8;



/* Code
------------------------------------------------------------------- */

$code-background-color: scale-color($secondary-color, $lightness: 70%);

$highlight-background: #ffffff;
$highlight-comment: #999988;
$highlight-error: #a61717;
$highlight-comment-special: #999999;
$highlight-deleted: #000000;
$highlight-error-2: #aa0000;
$highlight-literal-string: #d14;
$highlight-literal-number: #009999;
$highlight-name-attribut: #008080;
$highlight-error-background: #e3d2d2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: #445588;
$highlight-name-builtin: #0086B3;
$highlight-name-class: #445588;
$highlight-name-entity: #800080;
$highlight-name-exception: #990000;
$highlight-name-function: #990000;
$highlight-name-namespace: #555555;
$highlight-name-tag: #000080;
$highlight-text-whitespace: #bbbbbb;
$highlight-literal-string-regex: #009926;
$highlight-literal-string-symbol: #990073;

$header-font-color: $colgate-maroon;
